<script setup lang="ts">
import {
  surveyResponseGateway,
  SurveyType,
  SourceType,
} from '@msl/survey-response-gateway-sdk';
import { WsButton } from '@mfl/common-components';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  surveyId: string;
  source: number;
  type: number | undefined;
}>();
const { surveyId, type } = props;
const logo = ref<string | undefined>('');
const isCopied = ref<boolean>(false);

watch(
  () => props.source,
  () => {
    isCopied.value = false;
  }
);
const fetchLogo = async () => {
  try {
    const res = await surveyResponseGateway.get({ id: surveyId });
    if (res.statusCode !== 0 || res.type === undefined) {
      console.error('Survey is not defined.');
      return;
    }

    logo.value = res.logo;
  } catch (_err) {
    console.error('Error fetching logo. Try again later.');
  }
};

const platformSpecific = () => {
  switch (props.source) {
    // zendesk
    case 2:
      return (
        `${SourceType[props.source]}&customer_email={{ticket.requester.email}}&team_member_email={{ticket.assignee.email}}` +
        '&company_name={{ticket.requester.organization.name}}&customer_id={{ticket.requester.id}}' +
        '&ticket_id={{ticket.id}}&ticket_subject={{ticket.title}}&team_member_first_name={{ticket.assignee.first_name}}' +
        '&team_member_last_name={{ticket.assignee.last_name}}&customer_first_name={{ticket.requester.first_name}}' +
        '&customer_last_name={{ticket.requester.last_name}}&t_group={{ticket.group.name}}'
      );
    // customer.io
    case 3:
      return (
        `${SourceType[props.source]}&customer_email={{customer.email}}&plan={{plan}}&t_plan={{plan}}` +
        '&t_channel=cio-default&t_campaign=NPS_1m'
      );
    // vitally
    case 4:
      return (
        `${SourceType[props.source]}&customer_email={{account.traits.ws_accountOwnerEmail}}&customer_id={{account.externalId}}` +
        '&plan={{account.traits.ws_plan}}&company_size={{account.traits.ws_PlanSeats}}&csm={{csm}}'
      );
    default:
      return `${SourceType[props.source]}`;
  }
};

const getRawHTML = () => {
  return type === SurveyType.csat
    ? `<div style="text-align: center; display: block;
  width: 100%; margin: 0 auto;">
  <h3 style="font-weight: bold; font-family: arial,sans-serif; margin: 0; color: #373f46;">How satisfied are you with our service?</h3>
  <table style="border: 0 !important;  margin: 0 auto;" cellpadding="5">
    <tbody>
      <tr>
        <td style="vertical-align: top; text-align: center; padding-left: 0;"><a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=1&source=${platformSpecific()}"><img style="font-size: 10px; font-weight: bold; color: #fff; display: block; margin: auto;" src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="Angry" width="60" height="60" border="0" /></a></td>
        <td style="vertical-align: top; text-align: center; padding-left: 0;"><a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=2&source=${platformSpecific()}"><img style="font-size: 10px; font-weight: bold; color: #fff; display: block; margin: auto;" src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f61e/512.gif" alt="Sad" width="60" height="60" border="0" /></a></td>
        <td style="vertical-align: top; text-align: center; padding-left: 0;"><a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=3&source=${platformSpecific()}"><img style="font-size: 10px; font-weight: bold; color: #fff; display: block; margin: auto;" src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f642/512.gif" alt="Slightly-happy" width="60" height="60" border="0" /></a></td>
        <td style="vertical-align: top; text-align: center; padding-left: 0;"><a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=4&source=${platformSpecific()}"><img style="font-size: 10px; font-weight: bold; color: #fff; display: block; margin: auto;" src="https://fonts.gstatic.com/s/e/notoemoji/latest/263a_fe0f/512.gif" alt="Warm-smile" width="60" height="60" border="0" /></a></td>
        <td style="vertical-align: top; text-align: center; padding-left: 0;"><a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=5&source=${platformSpecific()}"><img style="font-size: 10px; font-weight: bold; color: #fff; display: block; margin: auto;" src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/512.gif" alt="Heart-eyes" width="60" height="60" border="0" /></a></td>
      </tr>
    </tbody>
  </table>
  </div>
`
    : `<table class="es-wrapper" width="100%"
  cellspacing="0" cellpadding="0"
  style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#F6F6F6">
  <tr>
    <td valign="top" style="padding:0;Margin:0">
      <table class="es-content" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
        <tr>
          <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px">
              <tr>
                <td align="left" style="padding:20px;Margin:0">
                  <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                    <tr>
                      <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                        <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                          <tr>
                            <td align="center" style="padding:0;Margin:0;font-size:0px"><img src="${logo.value}" alt="logo" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" width="130"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding:0;Margin:0;padding-top:10px;padding-left:20px;padding-right:20px">
                  <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                    <tr>
                      <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                        <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                          <tr>
                            <td align="left" style="padding:0;Margin:0"><h1 style="Margin:0;line-height:45px;mso-line-height-rule:exactly;font-family:Mulish, Helvetica, Arial, sans-serif;font-size:30px;font-style:normal;font-weight:normal;color:#08415C;text-align:center"><b>Your feedback is important to us!</b><br></h1></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding:0;Margin:0;padding-left:20px;padding-right:20px">
                  <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                    <tr>
                      <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                        <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                          <tr>
                            <td align="left" style="padding:0;Margin:0;padding-bottom:20px"><h2 style="Margin:0;line-height:30px;mso-line-height-rule:exactly;font-family:Mulish, Helvetica, Arial, sans-serif;font-size:20px;font-style:normal;font-weight:normal;color:#08415C;text-align:center"><b>(Join 76% of our users who answered this)</b></h2></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding:0;Margin:0;padding-top:10px;padding-left:20px;padding-right:20px">
                  <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                    <tr>
                      <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                        <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                          <tr>
                            <td style="border:0 !important;padding-top:10px;display:inline-block;text-align:center;">
                              <!-- SimpleSAT -->
                              <h3 style="font-weight:bold;font-family:Mulish, Helvetica,sans-serif;font-size:20px;margin:0;color:#08415C;text-align:center">How likely are you to recommend us to a friend or colleague?</h3>
                              <table cellpadding="4" style="border:0 !important;padding-top:20px;display:inline-block;text-align:center;">
                                <tr>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=0&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">0</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=1&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">1</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=2&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">2</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=3&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">3</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=4&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">4</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=5&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">5</a>
                                  </td>
                                  <td style="background-color:#FFDDE1;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=6&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D00619;">6</a>
                                  </td>
                                  <td style="background-color:#FBEED4;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=7&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D2AA4B;">7</a>
                                  </td>
                                  <td style="background-color:#FBEED4;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=8&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#D2AA4B;">8</a>
                                  </td>
                                  <td style="background-color:#D2FADF;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=9&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#64BF89;">9</a>
                                  </td>
                                  <td style="background-color:#D2FADF;border:none;border-radius:25px;width:32px;text-align:center;text-decoration:none;height:32px;margin-right:3px;">
                                    <a href="https://surveys.wisestamp.com/s1/${surveyId}?rating=10&source=${platformSpecific()}" target="_blank" rel="noopener" style="text-decoration:none;display:block;font-family:arial,helvetica,sans-serif;font-size:18px;font-weight:bold;letter-spacing:0px;line-height:32px;color:#64BF89;">10</a>
                                  </td>
                                </tr>
                              </table>
                              <!-- /SimpleSAT -->
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding:0;Margin:0;padding-top:40px;padding-left:20px;padding-right:20px;">
                  <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                    <tr>
                      <td align="left" valign="top" style="padding:0;Margin:0;width:560px">
                        <table cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                          <tr>
                            <td align="left" style="padding:0;Margin:0;padding-bottom:20px">
                              <p style="Margin:0;line-height:20px;font-family:Mulish, Helvetica, Arial, sans-serif;font-size:18px;font-style:normal;font-weight:normal;color:#08415C;">Thank you for your time and feedback!</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
`;
};

const copy = async (text: string) => {
  await navigator.clipboard.writeText(text);
  isCopied.value = true;
  await new Promise((res) => setTimeout(res, 2000));
  isCopied.value = false;
};

onMounted(async () => {
  await surveyResponseGateway.init();
  await fetchLogo();
});
</script>
<template>
  <div>
    <WsButton
      aid="COPY_EMAIL_EMBED_BUTTON"
      variant="contained"
      size="sm"
      color="primary"
      :label="isCopied ? 'Copied!' : 'Copy'"
      @click="copy(getRawHTML())"
    />
    <pre><code>{{ getRawHTML() }}</code></pre>
  </div>
</template>
<style scoped lang="scss">
div:has(code) {
  background-color: rgb(var(--color-gray-100));
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  overflow: auto;

  code {
    font-family: 'Courier New', Courier, monospace;
    color: black;
  }

  button {
    position: absolute;
    right: 35px;
  }
}
</style>
