<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { QTableColumn, QTable, QTd } from 'quasar';
import { auth4Sdks } from '@mfl/platform-shell';
import {
  WsButton,
  WsBadge,
  WsMenu,
  WsMenuItem,
  WsIconButton,
  openDialog,
} from '@mfl/common-components';
import {
  surveysGateway,
  Survey,
  SurveyType,
  ResponseData,
} from '@msl/surveys-gateway-sdk';
import * as XLSX from 'xlsx';

import strings from './surveys.strings';
import { addEditSurvey } from './add-edit-survey';
import logo from './assets/empty-state.jpg';
import DeleteSurveyDialog from './delete-survey-dialog.vue';
import EmailEmbedDialog from './email-embed-dialog.vue';

// Create a reactive state for surveys
const surveys = ref<Survey[]>([]);
const isLoading = ref<boolean>(true);
const tableHeaders = [
  'Survey opened timestamp',
  'Survey submitted timestamp',
  'Q1: Score',
  'Q2: Text',
  'Source',
  'Email address',
  'Company name',
  'Company size',
  'Plan',
  'Team member email',
  'Team member name',
  'Ticket id',
  'Customer manager',
  'Customer ID',
];

const columns: QTableColumn[] = [
  {
    name: 'name',
    label: strings.tableNameColumn,
    field: 'name',
    align: 'left',
    sortable: true,
  },
  {
    name: 'type',
    label: strings.tableTypeColumn,
    field: (row: Survey) => row.type,
    format: (val: number) => SurveyType[val].toUpperCase(),
    align: 'left',
  },
  {
    name: 'createdAt',
    label: strings.tableCreatedColumn,
    field: (row: Survey) => row.createdAt,
    format: (val: number) =>
      new Date(val).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    align: 'left',
    sortable: true,
  },
  {
    name: 'action',
    label: '',
    field: 'action',
    align: 'center',
  },
];

onMounted(async () => {
  await surveysGateway.init(auth4Sdks());
  await fetchSurveys();
});

const fetchSurveys = async () => {
  try {
    const fetchedSurveys = await surveysGateway.list({});

    surveys.value = fetchedSurveys.surveys || [];
    isLoading.value = false;
  } catch (_err) {
    // TODO: inform the user
    console.error('Error fetching surveys. Please try again later.');
  } finally {
    isLoading.value = false;
  }
};

const openDeleteSurveyDialog = async (survey?: Survey): Promise<void> => {
  if (!survey) return;
  await openDialog({
    aid: 'DELETE_SURVEY_DIALOG',
    headerText: strings.dialogTitleDelete,
    component: DeleteSurveyDialog,
    showTopSeparator: false,
    input: survey,
  });
  surveys.value = surveys.value.filter((s) => s.id !== survey.id);
};

async function openAddEditSurveyDialog(survey?: Survey) {
  const newOrUpdatedSurvey = await addEditSurvey(survey);

  // Dialog was dismissed
  if (!newOrUpdatedSurvey) return;

  const isNewSurvey = survey?.id === undefined;
  if (isNewSurvey) {
    surveys.value.unshift(newOrUpdatedSurvey);
  } else {
    const index = surveys.value.findIndex((l) => l.id === survey.id);

    if (index !== -1) {
      Object.assign(surveys.value[index], newOrUpdatedSurvey);
    }
  }
}

const openEmailEmbedDialog = async (survey?: Survey): Promise<void> => {
  if (!survey) return;
  return await openDialog({
    aid: 'EMAIL_EMBED_DIALOG',
    headerText: strings.dialogTitleEmbed,
    component: EmailEmbedDialog,
    showTopSeparator: false,
    input: survey,
  });
};

const copyToClipboard = (id: string, slug: string) => {
  navigator.clipboard.writeText(
    slug
      ? `https://wisestamp.link/${slug}?source=link`
      : `https://surveys.wisestamp.com/s1/${id}?source=link`
  );
};

const generateTable = function (
  responses: ResponseData[],
  totalScore: number,
  totalOpened: number,
  lastOpenedDate: string
) {
  const scoreRow = [`score:`, `${totalScore}%`];
  const numberOfResponsesRow = ['Total Number of Responses:', `${totalOpened}`];
  const lastResponseDate = ['Last Response Date:', `${lastOpenedDate}`];

  const tableRows = [
    scoreRow,
    numberOfResponsesRow,
    lastResponseDate,
    [],
    ['Responses:'],
    [],
    tableHeaders,
  ];

  responses.forEach(
    ({
      openedAt,
      submittedAt = '-',
      score = '-',
      text = '-',
      source = 'link',
      emailAddress = '-',
      companyName = '-',
      companySize = '-',
      plan = '-',
      teamMemberEmail = '-',
      teamMemberName = '-',
      ticketId = '-',
      csm = '-',
      customerId = '-',
    }) => {
      tableRows.push([
        `${openedAt}`,
        `${submittedAt}`,
        `${score}`,
        `${text}`,
        `${source}`,
        `${emailAddress}`,
        `${companyName}`,
        `${companySize}`,
        `${plan}`,
        `${teamMemberEmail}`,
        `${teamMemberName}`,
        `${ticketId}`,
        `${csm}`,
        `${customerId}`,
      ]);
    }
  );

  return tableRows;
};

const exportResponses = async (id: string) => {
  const info = await surveysGateway.getResponses({ ids: [id] });
  const {
    responses = [],
    totalScore = 0,
    totalOpened = 0,
    lastOpenedDate = '-',
  } = info;
  const resData = generateTable(
    responses,
    totalScore,
    totalOpened,
    lastOpenedDate
  );
  const worksheet = XLSX.utils.aoa_to_sheet(resData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, 'export.xlsx');
};
</script>

<template>
  <div class="surveys-container">
    <div class="header">
      <div class="header-title">
        <span class="header-text">{{ strings.header }}</span>
        <WsBadge label="BETA" aid="SURVEYS_BETA_BADGE"></WsBadge>
      </div>
      <WsButton aid="ADD_SURVEY_BUTTON" v-if="surveys.length" color="primary">
        {{ strings.addSurvey }}
        <span class="fa-regular fa-chevron-down" />
        <WsMenu aid="MENU_BUTTON_ADD_SURVEY">
          <WsMenuItem
            aid="MENU_BUTTON_CSAT"
            @click="openAddEditSurveyDialog({ type: SurveyType.csat })"
          >
            {{ strings.menuButtonCSAT }}
          </WsMenuItem>
          <WsMenuItem
            aid="MENU_BUTTON_NPS"
            @click="openAddEditSurveyDialog({ type: SurveyType.nps })"
          >
            {{ strings.menuButtonNPS }}
          </WsMenuItem>
        </WsMenu>
      </WsButton>
    </div>

    <div
      v-if="!surveys.length && !isLoading"
      aid="EMPTY_STATE_CONTAINER"
      class="empty-state-container"
    >
      <img class="empty-state-logo" alt="surveys" :src="logo" />
      <span class="empty-state-header">{{ strings.emptyStateHeader }}</span>
      <span class="empty-state-text">{{ strings.emptyStateText }}</span>
      <WsButton aid="ADD_SURVEY_EMPTY_STATE_BUTTON" color="primary">
        {{ strings.addSurvey }}
        <span class="fa-regular fa-chevron-down" />
        <WsMenu aid="MENU_BUTTON_ADD_SURVEY">
          <WsMenuItem
            aid="MENU_BUTTON_CSAT"
            @click="openAddEditSurveyDialog({ type: SurveyType.csat })"
          >
            {{ strings.menuButtonCSAT }}
          </WsMenuItem>
          <WsMenuItem
            aid="MENU_BUTTON_NPS"
            @click="openAddEditSurveyDialog({ type: SurveyType.nps })"
          >
            {{ strings.menuButtonNPS }}
          </WsMenuItem>
        </WsMenu>
      </WsButton>
    </div>

    <div v-if="surveys.length || isLoading" class="table-container">
      <q-table
        aid="SURVEYS_TABLE"
        icon-prev-page="fa-regular fa-chevron-left"
        icon-next-page="fa-regular fa-chevron-right"
        :rows="surveys"
        :columns="columns"
        row-key="id"
        :loading="isLoading"
        :pagination="{ rowsPerPage: 10, sortBy: 'createdAt', descending: true }"
        no-data-label="No surveys available. Please add a new surveys."
        binary-state-sort
      >
        <template #body-cell-action="props">
          <q-td :props="props">
            <WsIconButton :aid="'MENU_BUTTON_' + props.row.id" variant="text">
              <span class="fa-solid fa-ellipsis" />
              <WsMenu :aid="'MENU_BUTTON_' + props.row.id">
                <WsMenuItem
                  :aid="'MENU_BUTTON_EMBED_' + props.row.id"
                  icon="fa-light fa-envelope"
                  @click="openEmailEmbedDialog(props.row)"
                >
                  {{ strings.menuButtonEmbed }}
                </WsMenuItem>

                <WsMenuItem
                  :aid="'MENU_BUTTON_COPY_' + props.row.id"
                  icon="fa-light fa-copy"
                  @click="copyToClipboard(props.row.id, props.row.slug)"
                >
                  {{ strings.menuButtonShare }}
                </WsMenuItem>

                <WsMenuItem
                  :aid="'MENU_BUTTON_DOWNLOAD_' + props.row.id"
                  icon="fa-light fa-download"
                  @click="exportResponses(props.row.id)"
                >
                  {{ strings.menuButtonExportResponses }}
                </WsMenuItem>

                <WsMenuItem
                  :aid="'MENU_BUTTON_EDIT_' + props.row.id"
                  icon="fa-light fa-pen"
                  @click="openAddEditSurveyDialog(props.row)"
                >
                  {{ strings.menuButtonEdit }}
                </WsMenuItem>

                <WsMenuItem
                  :aid="'MENU_BUTTON_DELETE_' + props.row.id"
                  icon="fa-light fa-trash-can"
                  @click="openDeleteSurveyDialog(props.row)"
                >
                  {{ strings.menuButtonDelete }}
                </WsMenuItem>
              </WsMenu>
            </WsIconButton>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<style scoped>
.fa-chevron-down {
  padding-left: 10px;
}

.surveys-container {
  margin: 12px 24px;

  .table-container {
    padding-top: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    .header-text {
      margin-right: 5px;
    }
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-state-logo {
    display: block;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    width: 319.94px;
    height: 244.83px;
  }

  .empty-state-header {
    padding-top: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  .empty-state-text {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }
}
</style>
